<template>
  <div class="patient-credit">
    <div class="patient-credit__history">
      <div class="patient-credit__history-header">
        <div>Depósito/Saque</div>
        <b-button
          v-can="'FiConCred2'"
          class="wh-button"
          @click="openNewBillToReceiveModal"
          v-if="isRouteAppointment"
        >
          <PlusBlueIcon class="icon" />
          <span> Novo depósito </span>
        </b-button>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Data</th>
            <th>Tipo</th>
            <th>Valor</th>
            <th>Mét. recebimento</th>
            <th>Conta Bancária</th>
            <th>Forma de pag.</th>
            <th>Descrição</th>
            <th>Conta</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bill in bills" :key="bill.id">
            <td>{{ moment(bill.service_date).isValid() ? moment(bill.service_date).format('DD/MM/YYYY') : '-' }}</td>
            <td>{{ getType(bill) }}</td>
            <td>{{ getCreditFormatted(bill) }}</td>
            <td>{{ getPaymentMethods(bill) }}</td>
            <td>{{ getBankAccounts(bill) }}</td>
            <td>{{ getInstallmentsLabel(bill) }}</td>
            <td>{{ bill.description }}</td>
            <td><EyeIcon class="eye-icon" @click="openBill(bill)" v-b-tooltip="'Visualizar'"/></td>
          </tr>

        </tbody>
      </table>

      <div class="d-flex justify-content-end">

        <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        />
      </div>
    </div>

    <BillsToReceiveModal
      ref="modal-patient-credit"
      :billToReceiveId="billToReceiveId"
      :categorySelected="categorySelected"
      :patientSelected="patient"
      v-if="isRouteAppointment"
      @onHide="getCreditResume"
    />
  </div>
</template>
<script>
import financialApi from '@/modules/financial/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  components: {
    EyeIcon: () => import('@/assets/icons/eye.svg'),
    PlusBlueIcon: () => import('@/assets/icons/plus-blue.svg'),
    BillsToReceiveModal: () => import('@/modules/financial/modals/BillsToReceiveModal'),
  },
  props: {
    patient: Object,
    categorySelected: Object
  },

  computed: {
    isRouteAppointment() {
      return this.$route.path.includes('agenda')
    },
    deposits() {
      return this.bills?.length || 0
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      showFields: {
        showBeneficiaryField: false,
        showFieldHealthPlan: false,
        showFieldAmount: false,
        showFieldDiscount: false,
        showFieldCurrency: false,
        showFieldFinalAmount: false,
        showFieldPaymentVendorId: true
      },
      billToReceiveId: null,
      currentCredit: null,
      payments: null,
      bills: [],
      page: 1,
      limit: 10,
      count: 0,
    }
  },
  methods: {
    openNewBillToReceiveModal() {
      this.$bvModal.show('bills-to-receive-modal')
    },
    openBill(bill) {
      this.billToReceiveId = bill.id

      if (this.isRouteAppointment) {
        this.$refs['modal-patient-credit'].show()
      } else { 
        this.$emit('open-bill', bill.id)
      }
    },
    getCreditFormatted(bill) {
      const amount = this.getType(bill) !== 'Saque' ? 
        bill.payments.reduce((acc, payment) => acc + payment.amount, 0) : 
        bill.payments.filter(payment => payment.payment_method.name === 'Crédito do paciente').reduce((acc, payment) => acc + payment.amount, 0)

      return `R$ ${parseNumberToMoney(amount).trim().split('$')[1]}`
    },
    async getCreditResume() {
      try {
        if (!this.patient || !this.clinic || !this.categorySelected) return
        const response = await financialApi.getCreditResume({
          patient: this.patient.id,
          clinic_id: this.clinic.id,
          category: this.categorySelected.id
        })

        if (response) {
          this.currentCredit = response.data.currentCredit
          this.payments = response.data.sumPaymentsUsingCredit
          this.bills = response.data.billsToReceive          

          this.$emit('informations', {
            deposits: response.data.depositCount || 0,
            withdraws: response.data.withdrawCount || 0,
            currentCredit: this.currentCredit,
            payments: this.payments
          })
        }


      } catch (error) {
        console.error(error)
      }
    },
    getType(bill){
      return bill.payments.some(payment => payment.payment_method.name === 'Crédito do paciente') ? 'Saque' : 'Depósito'
    },
    getPaymentMethods(bill) {
      return bill?.payments?.map(payment => payment.payment_method.name).join(', ')
    },
    getBankAccounts(bill) {
      return bill?.payments?.map(payment => payment.bank_account?.name ? payment.bank_account?.name : '<< Conta Excluída >>').join(', ')
    },
    getInstallmentsLabel(bill) {
      return bill?.payments[0]?.installments ? 
        bill?.payments[0]?.installments.length > 1 ?  `Parcelado em ${bill.payments[0]?.installments.length}x` : 'À vista'
        : '-'
    }
  },
  watch: {
    categorySelected: {
      handler() {
          const isLoading = this.$loading.show()
          this.getCreditResume()
          isLoading.hide()
      },
      immediate: true
    },
    async page() {
      await this.getCreditResume()
    },
  }
}
</script>
<style lang="scss" scoped>
.patient-credit {


  .patient-credit__history {
    border: 1px solid #c6ceeb;
    border-radius: 8px;

    padding: 24px 16px;
    margin-top: 20px;

    .patient-credit__history-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #0c1d59;

      .wh-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        border: none;

        font-size: 16px;
        background-color: #E7ECFE;
        color: #305BF2;

        .icon {
          margin-right: 5px;
        }
      }
    }

    .table {
      border: none;
    }

    table {
      width: 100%;
      margin-top: 16px;
      border-collapse: collapse;

      thead {
        color: var(--dark-blue);
        font-weight: 700;
        font-size: 14px;
        background-color: var(--neutral-100);
      }

      th {
        line-height: 20px;
        text-align: left;
        padding-bottom: 8px;
        border: none;
      }

      td {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #4f4f4f;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .eye-icon {
    fill: var(--blue-500);
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin-left: 5px;
  }
}
</style>
